<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row align="center" justify="center">
          <!-- <v-col cols="4">
            <v-text-field
              outlined
              dense
              type="date"
              label="From Date"
              v-model="record.FromDate"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              type="date"
              label="To Date"
              v-model="record.ToDate"
            ></v-text-field>
          </v-col> -->
          <!-- <v-col cols="4">
          <v-autocomplete
            outlined
            dense
            v-model="record.Repeat"
            :items="repeatData"
            item-text="name"
            item-value="value"
            label="Repeat"
          ></v-autocomplete>
        </v-col> -->

          <!-- <v-col cols="4">
            <v-text-field
              v-model="record.Dscription"
              label="Comments"
              auto-grow
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              label="Create Calls?"
              required
              v-model="record.CreateCall"
            ></v-checkbox>
          </v-col> -->
        </v-row>
      </v-container>
      <v-btn color="primary" @click="addAssignmentLine" small class="my-2">
        <v-icon left dark>mdi-plus</v-icon> Add Item
      </v-btn>
      <!-- <div
        v-for="(assignment_line, index) in assignment_lines"
        :key="index"
        class="pa-2 mb-2"
      >
        <v-row dense>
          <v-col cols="2">
            <v-text-field
              outlined
              dense
              type="text"
              label="Route"
              v-model="assignment_line.RouteCode"
            ></v-text-field>
          </v-col>
          <v-text-field
            outlined
            dense
            type="date"
            label="date"
            v-model="assignment_line.Date"
          ></v-text-field>
          <v-col cols="12" sm="6" md="1">
            <v-btn small color="error" @click="removeAssignmentLine(index)">
              Remove
            </v-btn>
          </v-col>
        </v-row>
      </div> -->
      <template>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="assignment_lines"
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:[`item.counter`]="{ index }">
              <span>{{ index + 1 }}</span>
            </template>

            <template v-slot:[`item.SlpCode`]="{ item }">
              <v-autocomplete
                outlined
                dense
                v-model="item.SlpCode"
                :items="salesEmployees"
                item-text="SlpName"
                item-value="SlpCode"
                label="Sales Rep"
              ></v-autocomplete>
            </template>

            <template v-slot:[`item.OwnerCode`]="{ item }">
              <v-autocomplete
                outlined
                dense
                v-model="item.OwnerCode"
                :items="employees"
                item-value="empID"
                item-text="full_name"
                label="OwnerCode"
              ></v-autocomplete>
            </template>

            <template v-slot:[`item.RouteCode`]="{ item }">
              <v-autocomplete
                outlined
                dense
                v-model="item.RouteCode"
                :items="routes"
                item-text="Name"
                item-value="Code"
                label="Route"
              ></v-autocomplete>
            </template>

            <template v-slot:[`item.DocDate`]="{ item }">
              <v-text-field
                outlined
                dense
                type="date"
                label="Date"
                v-model="item.DocDate"
              ></v-text-field>
            </template>
            <!-- 
            <template v-slot:[`item.Remarks`]="{ item }">
              <v-text-field
                outlined
                dense
                type="text"
                label="Remarks"
                v-model="item.documents"
              ></v-text-field>
            </template> -->

            <template v-slot:[`item.Comments`]="{ item }">
              <v-text-field
                outlined
                dense
                type="text"
                label="Comments"
                v-model="item.Comments"
              ></v-text-field>
            </template>

            <template v-slot:[`item.CreateCall`]="{ item }">
              <v-checkbox
                label="Create Calls?"
                required
                v-model="item.CreateCall"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.actions`]="{ index }">
              <v-btn small color="error" @click="removeAssignmentLine(index)">
                <v-icon left dark>mdi-minus</v-icon> Remove
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </template>
      <v-row>
        <v-col cols="6">
          <v-btn
            v-if="record.WddStatus == 'Y'"
            class="mr-1 success"
            variant="success"
            @click="sendData"
          >
            <v-icon left dark>mdi-content-save</v-icon>Add Document
          </v-btn>
          <div v-else>
            <v-btn class="mr-1 success" variant="success" @click="savedata">
              <v-icon left dark>mdi-content-save</v-icon>Save
            </v-btn>
            <v-btn
              v-if="$route.query.DocStatus !== 1"
              class="mr-1 warning"
              variant="warning"
              @click="saveDraft"
            >
              <v-icon left dark>mdi-content-save</v-icon>Save as Draft
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="localRemarksDialog" persistent width="850px">
        <v-card>
          <v-toolbar color="accent">
            <v-toolbar-title>Add Remarks</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-col cols="4">
              <v-text-field
                outlined
                dense
                v-model="DocApproval.remarks"
                label="Remarks"
              ></v-text-field> </v-col
          ></v-card-text>
          <v-btn class="mr-1 primary" variant="primary" @click="DocSave">
            <v-icon left dark>mdi-content-save</v-icon>Save
          </v-btn>
        </v-card>
      </v-dialog>
    </v-card-text>
    <snackbar ref="snackbar"></snackbar>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
    },
    activeRoute: {
      type: Object,
    },
    id: {
      type: String,
    },
    outlets: {
      type: Array,
    },
    RemarksDialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      DocApproval: {},
      record: {},
      loader: false,
      menu2: false,
      menu3: false,
      search: null,
      searchInput: null,
      selectionType: "leaf",
      trueValue: true,
      editMode: false,
      // loading: false,
      employees: [],
      salesEmployees: [],
      headers: [
        { text: "No.", value: "counter", align: "start" },
        { text: "Route", value: "RouteCode", align: "start" },
        { text: "SlpCode", value: "SlpCode" },
        { text: "OwnerCode", value: "OwnerCode" },
        { text: "DocDate", value: "DocDate" },
        { text: "Comments", value: "Comments" },
        { text: "CreateCall", value: "CreateCall" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      assignment_lines: [],
      routes: [],
      repeatData: [
        { name: "Never", value: "N" },
        { name: "Daily", value: "D" },
        { name: "Weekly", value: "W" },
        { name: "Fortnightly", value: "F" },
        { name: "Monthly", value: "M" },
        { name: "Annually", value: "A" },
      ],
    };
  },
  watch: {
    initial: {
      handler: "setAssignment",
      immediate: true,
    },
  },
  computed: {
    localRemarksDialog: {
      get() {
        return this.RemarksDialog;
      },
      set(value) {
        this.$emit("update:RemarksDialog", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.localRemarksDialog = false;
    },
    removeAssignmentLine(index) {
      this.assignment_lines.splice(index, 1);
    },

    addAssignmentLine() {
      this.assignment_lines.push({
        OwnerCode: "",
        SlpCode: "",
        RouteCode: "",
        DocDate: "",
        Comments: "",
        CreateCall: "",
      });
    },

    setAssignment(val) {
      this.record = { ...val };
      console.log(this.record);
      this.assignment_lines = this.assignment_lines || [];
      if (this.record) {
        this.assignment_lines.push(this.record);
      }
      //this.record.IsDraft = 0;
      this.record.documents = this.assignment_lines.map((line) => ({
        ...line,
        IsDraft: 0,
      }));
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee-master-data`)
        .then((res) => {
          self.employees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    savedata() {
      this.record.documents = this.assignment_lines.map((line) => ({
        ...line,
        ObjType: 312,
        IsDraft: false,
      }));

      let data = {
        documents: this.record.documents,
        IsDraft: false,
        ObjType: 312,
      };
      this.sendData(data);
    },
    sendData(data) {
      this.$store.commit("loader", true);
      this.$emit("data", data);
    },

    DocSave() {
      console.log("DATA", this.record);
      this.record.documents = this.assignment_lines;
      this.record.ObjType = 312;
      const url = "/route_assignments";
      const self = this;
      this.record.DocApproval = this.DocApproval;
      const data = this.record;
      console.log("DATA", data);
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show("Success", "green");
          // this.getAssignments();
          this.closeDialog();
          this.RemarksDialog = false;
        })
        .catch((err) => {
          console.log(err);
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err, "red");
        });
    },
    saveDraft() {
      this.record.documents = this.assignment_lines.map((line) => ({
        ...line,
        ObjType: 312,
      }));

      let data = {
        documents: this.record.documents,
        IsDraft: true,
      };
      this.sendData(data);
    },
    setDate(DocDate) {
      this.record.ObjType = 312;
      this.record.DocDueDate = DocDate;
      this.record.DocDate = DocDate;
    },
    getSalesEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getRoutes() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/routes_planning`)
        .then((res) => {
          self.routes = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getEmployees();
    this.getRoutes();
    this.getSalesEmployees();
  },
};
</script>

<style lang="scss" scoped></style>
